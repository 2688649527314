<!--
 * @Author: gaojingran
 * @Date: 2021-03-22 10:41:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-13 10:28:43
 * @Description: modules 页面 layout
-->

<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <a-layout class="neo-frame-layout full">
    <!-- sider -->
    <a-layout-sider class="neo-frame-layout-sider" width="216" :trigger="null">
      <div class="logo"></div>
      <NeoScroll class="menu-container">
        <SiderMenu :todoCount="todoCount" :orderCount="orderCount" />
      </NeoScroll>
      <div class="copy-right">
        <p :title="copyright">{{ copyright }}</p>
      </div>
    </a-layout-sider>
    <a-layout>
      <!-- header -->
      <a-layout-header class="neo-frame-layout-header">
        <a-row>
          <a-col :span="12" class="header-left">
            <a-button
              type="primary"
              v-if="$role('order_newOrder_create') && excludeNames.excludes($route.name)"
              @click="orderNow"
            >
              {{ $t('layout.new_order') }}
            </a-button>
          </a-col>
          <a-col :span="12" class="header-right">
            <!-- 草稿箱 -->
            <span class="item pointer" v-if="$role('order_draftBox_view')">
              <a-badge :count="draftCount" :offset="[12, -12]">
                <NeoIcon name="caogaoxiang" :size="26" />
              </a-badge>
              <span class="ml-2 label" @click="$router.push({ name: 'order_draftBox' })">{{
                $t('layout.draft_box')
              }}</span>
            </span>
            <!-- 退出登录 -->
            <a-dropdown>
              <span class="item pointer">
                <NeoIcon name="avatar" :size="32" />
                <span class="ml-1 label">{{ userInfo.realName }}</span>
                <a-icon class="icon-down ml-1 fs-2 c-2" type="down" />
              </span>
              <a-menu slot="overlay" @click="handleDropClick">
                <a-menu-item v-for="(item, i) in avatarMenuList" :key="i">
                  {{ item.label }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-layout-header>

      <!-- content -->
      <a-layout-content class="neo-frame-layout-content">
        <!-- Nav tab -->
        <NavTab v-if="tabList.length" :tabList.sync="tabList" />

        <keep-alive>
          <router-view v-if="$route.fullPath == '/Rockwell/project/list' || $route.fullPath == '/Rockwell/payment'" />
        </keep-alive>
        <router-view v-if="$route.fullPath !== '/Rockwell/project/list' && $route.fullPath !== '/Rockwell/payment'" />
      </a-layout-content>

      <!-- 确认企业信息 -->
      <ConfirmEnterInfo />
    </a-layout>
  </a-layout>
</template>

<script>
// import moment from 'moment'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { mapState } from 'vuex'
import { clearLoginInfo, uuid } from '@/utils/utils'
import { storage } from '@/utils/storage'
import NavTab from './components/NavTab'
import SiderMenu from './components/SiderMenu'
import ConfirmEnterInfo from '@/components/ConfirmEnterInfo'

const { storageKey } = window.$g

export default {
  name: 'Layout',
  components: {
    NavTab,
    SiderMenu,
    ConfirmEnterInfo,
  },
  data() {
    return {
      copyright: `Copyright © 2022 CEC GienTech Technology Co., Ltd. All rights reserved.`,
      tabList: [],
      avatarMenu: [
        {
          label: this.$t('layout.update_password'),
          type: 'url',
          name: 'user_password',
        },
        {
          label: this.$t('layout.update_userInfo'),
          type: 'url',
          name: 'user_userInfo',
        },
        {
          label: this.$t('layout.logout'),
          type: 'action',
          action: 'handleLogout',
        },
      ],
      todoCount: 0,
      draftCount: 0,
      orderCount: 0,
      excludeNames: ['home', 'Stone_home', 'order_newOrder', 'Rockwell_order_newOrder', 'Stone_order_newOrder'],
    }
  },
  provide() {
    // 是否存在tab栏目
    return { layoutWithTab: () => this.tabList.length > 0 }
  },
  computed: {
    ...mapState('app', {
      // 用户信息
      userInfo: (state) => state.userInfo,
      // 企业基本信息
      cusAgencyInfo: (state) => state.cusAgencyInfo,
    }),
    avatarMenuList() {
      const [item_pwd, , item_logout] = this.avatarMenu
      return this.$g.isAdmin ? [item_pwd, item_logout] : this.avatarMenu
    },
  },
  watch: {
    $route(route) {
      const { ignoreNav } = window.$g
      if (ignoreNav.indexOf(route.name) < 0) {
        if (route.name === 'order_newOrder' && route.query.id) {
          return
        }
        if (!this.tabList.find((t) => t.name === route.name && isEqual(t.query, route.query))) {
          this.tabList.push({
            ...route,
            uuid: uuid(),
          })
        }
      }
    },
    tabList(v) {
      storage.set(
        storageKey.NAVTAB,
        v.map((v) => omit(v, ['matched']))
      )
    },
  },
  methods: {
    orderNow() {
      this.$router.push({ path: this.$g.route.order_newOrder })
    },
    // dropdown click
    handleDropClick({ key }) {
      const item = this.avatarMenuList[key]
      console.log(`item`, item)
      if (item.type === 'url') {
        this.$router.push({ name: item.name })
      }
      if (item.type === 'action') {
        this[item.action]()
      }
    },
    // 退出登录
    async handleLogout() {
      this.$g.clearLocalStorage()
      await this.$http('logout')
      clearLoginInfo()
      this.clearTimer()
      this.$g.returnLogin()
    },
    // clear time
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    // 草稿箱 + 订单列表 + 待办事项 count
    async loopCount() {
      try {
        const [count, todo, draft] = await Promise.all([
          this.$http('orderStatusCount'),
          this.$http('orderStatisticTodoCount'),
          this.$http('orderDraftPage'),
        ])
        this.todoCount = Number(todo || 0)
        this.orderCount = Number(count.find((v) => v.status === -1)?.count || 0)
        this.draftCount = Number(draft.total || 0)
        this.clearTimer()
        this.timer = setTimeout(this.loopCount, 120000)
      } catch (err) {
        console.log('loopCount error ==>', err)
      }
    },
  },
  beforeDestroy() {
    this.clearTimer()
  },
  mounted() {
    console.log('sssss', this.$route)
    this.loopCount()
  },
}
</script>
